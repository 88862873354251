define("discourse/plugins/tacks-plugin/discourse/connectors/before-group-container/points-required", ["exports", "discourse/lib/ajax", "@ember/service"], function (_exports, _ajax, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    messageBus: (0, _service.inject)(),
    setupComponent(args, component) {
      if (this.currentUser) {
        const username = component.currentUser.username;
        (0, _ajax.ajax)(`/user-points/${username}`).then(data => {
          component.set("points", data.points);
        });
        this.get("messageBus").subscribe(`/user/${username}/points`, points => {
          component.set("points", points);
        });
      }
    }
  };
});