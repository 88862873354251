define("discourse/plugins/tacks-plugin/discourse/initializers/extend-group-membership-button", ["exports", "discourse/lib/plugin-api", "discourse/lib/ajax", "@ember/object", "@ember/object/proxy", "@ember/object/promise-proxy-mixin", "I18n", "discourse/lib/computed"], function (_exports, _pluginApi, _ajax, _object, _proxy, _promiseProxyMixin, _I18n, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializePlugin(api) {
    const MessageBus = api.container.lookup("message-bus:main");
    const GroupMembershipButton = api.container.factoryFor("component:group-membership-button").class;
    let originalJoinTranslation = _I18n.default.t("groups.join");
    GroupMembershipButton.reopen({
      tacksOverrideJoinButton: (0, _computed.setting)("tacks_override_join_button"),
      init() {
        this._super(...arguments);
        if (this.tacksOverrideJoinButton) {
          this.subscribeToUserPoints();
        }
      },
      willDestroyElement() {
        this._super(...arguments);
        if (this.tacksOverrideJoinButton) {
          this.unsubscribeFromUserPoints();
          _I18n.default.translations[_I18n.default.locale].js.groups.join = originalJoinTranslation;
        }
      },
      userPointsValue: null,
      userPoints: (0, _object.computed)("currentUser", "userPointsValue", function () {
        if (!this.tacksOverrideJoinButton) {
          return 0;
        }
        if (this.userPointsValue !== null) {
          return this.userPointsValue;
        }
        if (!this.currentUser) {
          return 0;
        }
        let promise = (0, _ajax.ajax)(`/user-points/${this.currentUser.username}`).then(response => response.points);
        return _proxy.default.extend(_promiseProxyMixin.default).create({
          promise
        });
      }),
      canJoinGroup: (0, _object.computed)("model.public_admission", "userIsGroupUser", "userPoints", function () {
        if (!this.tacksOverrideJoinButton) {
          return this._super(...arguments);
        }
        if (!this.currentUser || !this.model.public_admission || this.userIsGroupUser) {
          return false;
        }
        let customFields = this.model || {};
        let pointsRequired = customFields.points_required;
        let username = this.currentUser.username;
        let groupName = this.model.name;
        let promise = (0, _ajax.ajax)(`/can-join-group/${username}/${groupName}`).then(response => {
          if (response.can_join) {
            if (pointsRequired !== undefined && pointsRequired !== null && pointsRequired > 0) {
              _I18n.default.translations[_I18n.default.locale].js.groups.join = `Join (${pointsRequired} Tacks Deducted from Account)`;
            } else {
              _I18n.default.translations[_I18n.default.locale].js.groups.join = originalJoinTranslation;
            }
          }
          return response.can_join;
        });
        return _proxy.default.extend(_promiseProxyMixin.default).create({
          promise
        });
      }),
      subscribeToUserPoints() {
        if (this.currentUser) {
          MessageBus.subscribe(`/user/${this.currentUser.username}/points`, this.updateUserPoints.bind(this));
        }
      },
      unsubscribeFromUserPoints() {
        if (this.currentUser) {
          MessageBus.unsubscribe(`/user/${this.currentUser.username}/points`);
        }
      },
      updateUserPoints(newPoints) {
        this.set("userPointsValue", newPoints);
      }
    });
  }
  var _default = _exports.default = {
    name: "extend-group-membership-button",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.11.0", api => {
        if (api.container.lookup("site-settings:main").tacks_override_join_button) {
          initializePlugin(api);
        }
      });
    }
  };
});