define("discourse/plugins/tacks-plugin/discourse/templates/connectors/group-index-box-after/group-points", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if model.points_required}}
    <div class="group-points-page">
      <br />
      <b>Tacks Required: </b>{{model.points_required}}
    </div>
  {{/if}}
  {{!-- {{#if currentUser.user_groups}}
    {{#if (eq model.name vi_group_name)}}
      <div class="group-join-form add-margin-top">
        {{#if (not (includes currentUser.user_groups vi_group_name))}}
          You can also Join
          <button
            {{action "openAccessForm" preventDefault=false}}
            class="btn btn-primary"
          >Here</button>
        {{/if}}
      </div>
    {{/if}}
  {{#if (eq model.name pa_group_name)}}
      <div class="group-join-form add-margin-top">
        {{#if (not (includes currentUser.user_groups pa_group_name))}}
          You can also Join
          <button
            {{action "openAccessForm" preventDefault=false}}
            class="btn btn-primary"
          >Here</button>
        {{/if}}
      </div>
    {{/if}}
  {{/if}} --}}
  */
  {
    "id": "WOLSngBF",
    "block": "[[[41,[30,0,[\"model\",\"points_required\"]],[[[1,\"  \"],[10,0],[14,0,\"group-points-page\"],[12],[1,\"\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n    \"],[10,\"b\"],[12],[1,\"Tacks Required: \"],[13],[1,[30,0,[\"model\",\"points_required\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/tacks-plugin/discourse/templates/connectors/group-index-box-after/group-points.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/tacks-plugin/discourse/templates/connectors/group-index-box-after/group-points.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\"]]",
    "moduleName": "discourse/plugins/tacks-plugin/discourse/templates/connectors/group-index-box-after/group-points.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});