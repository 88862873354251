define("discourse/plugins/tacks-plugin/discourse/controllers/admin-plugins-tacks-plugin", ["exports", "@ember/controller", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/service"], function (_exports, _controller, _ajax, _ajaxError, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    saved: false,
    userSaved: false,
    groupSaved: false,
    username: "",
    points: 0,
    groupName: "",
    pointsRequired: 0,
    users: [],
    groups: [],
    email: "",
    apiKey: "",
    apiUsername: "",
    filteredUsers: [],
    filteredGroups: [],
    router: (0, _service.inject)(),
    usersLoaded: false,
    groupsLoaded: false,
    isLoading: true,
    init() {
      this._super(...arguments);
      this.router.addObserver('currentRouteName', this, 'routeChanged');
    },
    routeChanged() {
      if (this.router.currentRouteName === 'adminPlugins.tacks-plugin') {
        this.set('isLoading', true);
        if (!this.usersLoaded) {
          this.loadUsers();
        }
        if (!this.groupsLoaded) {
          this.loadGroups();
        }
        if (this.usersLoaded && this.groupsLoaded) {
          this.set('isLoading', false);
        }
      }
    },
    loadUsers() {
      (0, _ajax.ajax)("/admin/plugins/tacks-plugin/users").then(data => {
        const sortedUsers = data.users.sort((a, b) => a.username.localeCompare(b.username));
        this.set("users", sortedUsers);
        this.set("filteredUsers", sortedUsers);
        this.set('usersLoaded', true);
        this.checkLoading();
      });
    },
    loadGroups() {
      (0, _ajax.ajax)("/admin/plugins/tacks-plugin/groups").then(data => {
        this.set("groups", data.groups);
        this.set("filteredGroups", data.groups);
        this.set('groupsLoaded', true);
        this.checkLoading();
      });
    },
    checkLoading() {
      if (this.usersLoaded && this.groupsLoaded) {
        this.set('isLoading', false);
      }
    },
    willDestroy() {
      this.router.removeObserver('currentRouteName', this, 'routeChanged');
      this._super(...arguments);
    },
    fetchUserLogs(username) {
      (0, _ajax.ajax)(`/admin/plugins/tacks-plugin/logs/${username}`).then(data => {
        this.set('userLogs', data.logs);
      }).catch(_ajaxError.popupAjaxError);
    },
    actions: {
      filterUsers() {
        let term = document.getElementById('search-user-term').value;
        if (term === '') {
          this.set('username', '');
        }
        let rgx = new RegExp(term, 'i');
        this.set('filteredUsers', this.users.filter(u => rgx.test(u.username)));
      },
      selectUser(username) {
        this.set("username", username);
        this.set("showUserDropdown", false);
        this.fetchUserLogs(username);
      },
      filterGroups() {
        let term = document.getElementById('search-group-term').value;
        if (term === '') {
          this.set('groupName', '');
        }
        let rgx = new RegExp(term, 'i');
        this.set('filteredGroups', this.groups.filter(g => rgx.test(g.name)));
      },
      selectGroup(groupName) {
        this.set("groupName", groupName);
        this.set("showGroupDropdown", false);
      },
      updateUserPoints() {
        const data = {
          username: this.username,
          points: this.points
        };
        (0, _ajax.ajax)("/admin/plugins/tacks-plugin/user", {
          type: "POST",
          data
        }).then(() => {
          this.set("userSaved", true);
          setTimeout(() => this.set("userSaved", false), 2000);
        }).catch(_ajaxError.popupAjaxError);
      },
      addUserPoints() {
        const data = {
          username: this.username,
          points: this.points
        };
        (0, _ajax.ajax)("/admin/tacks-plugin/add-user-points", {
          type: "POST",
          data
        }).then(() => {
          this.set("userSaved", true);
          setTimeout(() => this.set("userSaved", false), 2000);
        }).catch(_ajaxError.popupAjaxError);
      },
      updateGroupPoints() {
        const data = {
          group_name: this.groupName,
          points_required: this.pointsRequired
        };
        (0, _ajax.ajax)("/admin/plugins/tacks-plugin/group", {
          type: "POST",
          data
        }).then(() => {
          this.set("groupSaved", true);
          this.loadGroups();
          setTimeout(() => this.set("groupSaved", false), 2000);
        }).catch(_ajaxError.popupAjaxError);
      },
      updateUserPointsApi() {
        const data = {
          api_key: this.apiKey,
          api_username: this.apiUsername,
          email: this.email,
          points: this.points
        };
        (0, _ajax.ajax)("/admin/plugins/tacks-plugin/update-points-api", {
          type: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json"
        }).then(() => {
          this.set("userSaved", true);
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  });
});