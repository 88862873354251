define("discourse/plugins/tacks-plugin/discourse/connectors/before-groups-index-container/show-user-points-group", ["exports", "@ember/service", "discourse/lib/ajax"], function (_exports, _service, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    messageBus: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    setupComponent(args, component) {
      if (this.currentUser) {
        const username = this.currentUser.get("username");
        (0, _ajax.ajax)(`/user-points/${username}`).then(data => {
          component.set("points", data.points);
        });
        this.messageBus.subscribe(`/user/${username}/points`, points => {
          component.set("points", points);
        });
        (0, _ajax.ajax)(`/user-groups/${username}`).then(data => {
          component.set("user_groups", data.groups);
        });
        this.messageBus.subscribe(`/user/${username}/groups`, groups => {
          component.set("user_groups", groups);
        });
      }
    },
    willDestroy() {
      this._super(...arguments);
      if (this.currentUser) {
        const username = this.currentUser.get("username");
        this.messageBus.unsubscribe(`/user/${username}/points`);
        this.messageBus.unsubscribe(`/user/${username}/groups`);
      }
    }
  };
});