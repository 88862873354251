define("discourse/plugins/tacks-plugin/discourse/connectors/group-index-box-after/group-points", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    currentUser: (0, _service.inject)(),
    siteSettings: (0, _service.inject)(),
    actions: {
      openAccessForm(event) {
        event.stopPropagation();
        event.preventDefault();
        const userGroups = this.currentUser.get("user_groups");
        if (!(userGroups.includes(this.siteSettings.sa_group_name) || userGroups.includes(this.siteSettings.pa_group_name))) {
          window.open(this.get("access_form_link"), "_blank");
        }
      }
    },
    setupComponent(args, component) {
      component.set("sa_group_name", this.siteSettings.sa_group_name);
      component.set("pa_group_name", this.siteSettings.pa_group_name);
      component.set("access_form_link", this.siteSettings.access_form_link);
    }
  };
});