define("discourse/plugins/tacks-plugin/discourse/services/site-settings", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    siteSettings: (0, _service.inject)()
  };
});