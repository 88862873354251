define("discourse/plugins/tacks-plugin/discourse/connectors/above-user-summary-stats/show-user-points", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      const username = component.currentUser.username;
      (0, _ajax.ajax)(`/user-points/${username}`).then(data => {
        component.set("points", data.points);
      });
    }
  };
});