define("discourse/plugins/tacks-plugin/discourse/helpers/format-date", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatDate = formatDate;
  function formatDate(_ref) {
    let [dateStr] = _ref;
    let date = new Date(dateStr);
    let formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    return formattedDate;
  }
  var _default = _exports.default = (0, _helper.helper)(formatDate);
});